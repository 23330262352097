<template>
    <div>
        <FilterLayout @doReset="resetForm" @doSearch="doSearch" ref="filterLayoutRef" :isShowEmail="true"
            @sendEmail="sendEmailHandler">
            <template #customform>
                <a-form ref="formRef" class="form rms-form box" layout="vertical" :model="formData">
                    <a-form-item label="Group Lead" name="Group Lead" class="rms-form-item">
                        <a-input allowClear v-model:value="formData.condition" placeholder="Name/ID Number"
                            autocomplete="off" />
                    </a-form-item>
                    <a-form-item label="Work Location" name="Work Location" class="rms-form-item">
                        <SelectWithAll v-model:value="formData['workLocationList']" placeholder="请搜索" mode="multiple"
                            showArrow allowClear :maxTagCount="1">
                            <a-select-option v-for="jtem in workListOption" :key="jtem" :value="jtem"><span :title="jtem">{{
                                jtem }}</span>
                            </a-select-option>
                        </SelectWithAll>
                    </a-form-item>
                    <a-form-item label="Org5.1" name="Org5.1" class="rms-form-item">
                        <SelectWithAll v-model:value="formData['orgList']" placeholder="请搜索" mode="multiple" showArrow
                            allowClear :maxTagCount="1">
                            <a-select-option v-for="jtem in orgListOption" :key="jtem" :value="jtem"><span :title="jtem">{{
                                jtem }}</span>
                            </a-select-option>
                        </SelectWithAll>
                    </a-form-item>
                    <a-form-item label="打卡日期" name="打卡日期" class="rms-form-item">
                        <a-range-picker :allowClear="false" v-model:value="formData.reportTime" :disabledDate="disabledDate"
                            @change="onChange" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" @openChange="onOpenChange"
                            @calendarChange="onCalendarChange" />
                    </a-form-item>
                </a-form>
            </template>
            <template #operationBtns>
                <div class="buttons-wrap right">
                    <Tooltip title="导出">
                        <a-button  type="primary" ghost @click="exportClick">
                            <ExportOutlined />
                        </a-button>
                    </Tooltip>
                </div>
            </template>
        </FilterLayout>

        <a-table ref="tableRef" :columns="columns" :data-source="tableData"
            :scroll="{ y: tableHeight, x: 1000, scrollToFirstRowOnChange: true }" :pagination="pagination"
            @change="handleTableChange">
        </a-table>

        <div ref="emailContainerRef">
            <a-modal v-model:visible="sendVisible" title="发送现场管理周报" width="50vw"
                :getContainer="() => $refs.emailContainerRef" :maskClosable="false">
                <a-form ref="emailFormRef" class="form rms-form box aModalForm" :model="emailData" :rules="emailRules">
                    <a-form-item class="timeRequired" required label="请选择统计日期" name="emailTime">
                        <a-range-picker v-model:value="emailData.emailTime" :placeholder="['起始日期', '终止日期']"
                            format="YYYY-MM-DD" valueFormat="YYYY-MM-DD">
                            <template #suffixIcon>
                                <CalendarOutlined />
                            </template>
                        </a-range-picker>
                    </a-form-item>
                    <div>
                        <a-form-item class="timeRequired textRequired" required label="请选择收件人" name="emailText">
                            <a-textarea :auto-size="{ minRows: 12 }" v-model:value="emailData.emailText"
                                placeholder='请输入收件人邮箱，如有多个请使用"；"隔开' />
                        </a-form-item>
                    </div>

                </a-form>
                <template #footer>
                    <a-button @click="sendVisible = !sendVisible">取消</a-button>
                    <a-button type="primary" @click="confirmSend">确定</a-button>
                </template>
            </a-modal>
        </div>
    </div>
</template>
<script>
import moment from "moment";
import { defineComponent, onMounted, ref, reactive } from "vue";
import { useGlobalPropertyHook } from '@/hooks/common'
import { useLifeCycle } from "../hooks/handler"
import { useHandler } from "../hooks/handler"
import { cloneDeep } from "lodash";
import { message } from "ant-design-vue";
import Tooltip from '@/components/Tooltip'
import {
    ExportOutlined,
    CalendarOutlined,
} from "@ant-design/icons-vue";
import { downloadFile } from "@/utils/common";
export default defineComponent({
    name: "Analysis",
    components: {
        Tooltip,
        ExportOutlined,
        CalendarOutlined
    },
    setup() {
        const { $api } = useGlobalPropertyHook()
        const { filterObj } = useHandler()
        const tableHeight = useLifeCycle()
        let sortData = {
            field: '',
            order: ''
        }

        onMounted(() => {
            getSelectionList()
            doSearch()
        })

        const getSelectionList = async () => {
            let [orgList, workList] = await Promise.all([$api.getOrgTwo_Three_Four_FiveList(), $api.getWorkLocationOption()])
            orgListOption.value = orgList.data.orgFiveOneList
            workListOption.value = workList.data
        }

        const disabledDate = (current) => {
            let flag = current && current > moment().endOf("day")
            if (!dates.value || dates.value.length === 0) {
                return flag;
            }
            const diffDate = current.diff(dates.value[0], 'days');
            flag = current && current > moment().endOf("day") || Math.abs(diffDate) > 31
            return flag
        };

        const onOpenChange = (open) => {
            if (open) {
                dates.value = [];
            }
        };

        const onChange = (val) => {
            value.value = val;
        };

        const onCalendarChange = (val) => {
            dates.value = val;
        };
        let tableRef = ref()
        const exportClick = async () => {
            let data = {
                seatApplicationBeginTime: formData.reportTime[0],
                seatApplicationEndTime: formData.reportTime[1],
                sortData: sortData.order === 'ascend' ? 0 : 1
            }
            let obj = filterObj(cloneDeep(formData))
            if(obj.condition){data.condition = obj.condition}
            if(obj.orgList){data.orgList = obj.orgList}
            if(obj.workLocationList){data.workLocationList = obj.workLocationList}
            let res = await $api.seatAnalysisExport(data)
            downloadFile(res.file, res.filename)
        }

        let sendVisible = ref(false)
        let emailData = reactive({
            emailTime: undefined,
            emailText: ''
        })
        let emailRules = {
            emailTime: [
                { required: true, message: '统计日期是必填项', trigger: ["change", "blur"], type: 'array' },
            ],
            emailText: [
                { required: true, message: '收件人是必填项', trigger: ["change", "blur"], type: 'string' },
            ],
        }
        const sendEmailHandler = () => {
            sendVisible.value = true
            emailFormRef?.value?.resetFields()
        }

        const checkEmails = (emails) => {
            // 使用正则表达式匹配邮箱格式
            const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
            // 按分号进行分割字符串为一个数组
            const emailArray = emails.split(';')
            // 遍历邮箱数组，检查每个邮箱是否符合格式
            for (let email of emailArray) {
                // 去除前后空格
                email = email.trim();
                // 使用正则表达式进行匹配
                if (!pattern.test(email)) {
                    return false;
                }
            }
            // 所有邮箱都符合格式，则返回 true
            return true;
        }
        let emailFormRef = ref()
        const confirmSend = () => {
            emailFormRef.value.validate().then(async () => {
                if (!checkEmails(emailData.emailText.trim())) {
                    message.error('收件人邮箱格式不对，请检查')
                    return
                }
                if (emailData.emailTime[0] == undefined) {
                    message.error('统计日期为必填项')
                    return
                }
                let data = {
                    sentBeginTime: emailData.emailTime[0],
                    seatEndTime: emailData.emailTime[1],
                    sendPersonEmail: emailData.emailText.trim()
                }
                try {
                    await $api.seatUsageSendEmail(data)
                    message.success('邮件发送成功')
                    sendVisible.value = false
                } catch (e) {
                    message.error(e.data.error.message)
                }
            });
        }

        const orgListOption = ref([])

        const workListOption = ref([])

        const value = ref([])
        const dates = ref([])

        // 下拉筛选动态下拉框
        let initData = {
            condition: '',
            workLocationList: [],
            orgList: [],
            reportTime: [
                moment().startOf('isoWeek').subtract(2, 'weeks').format('YYYY-MM-DD'),
                moment().format('YYYY-MM-DD')
            ],
        }

        const pagination = reactive({
            current: 1,
            pageSize: 10,
            total: 0,
            showSizeChanger: true,
            showTotal: (total) => {
                return `共 ${total} 条`;
            },
            onChange: (page, pageSize) => {
                pagination.current = page;
                pagination.pageSize = pageSize;
                let obj = filterObj(cloneDeep(formData))
                getTableData(obj);
            },
            onShowSizeChange: (_, size) => {
                pagination.pageSize = size;
                pagination.current = 1;
                let obj = filterObj(cloneDeep(formData))
                getTableData(obj);
            }
        });

        let formData = reactive({ ...initData });

        const doSearch = () => {
            let obj = filterObj(cloneDeep(formData))
            pagination.current = 1
            getTableData(obj)
        }

        const resetForm = () => {
            formData = Object.assign(formData, initData)
            let obj = filterObj(cloneDeep(formData))
            pagination.current = 1
            getTableData(obj)
        }

        const getTableData = async (obj) => {
            obj.pageIndex = pagination.current
            obj.pageSize = pagination.pageSize
            if (!('sort' in obj)) {
                obj.sort = 0
            }
            if (obj.reportTime && obj.reportTime.length) {
                obj.seatApplicationBeginTime = obj.reportTime[0]
                obj.seatApplicationEndTime = obj.reportTime[1]
                delete obj.reportTime
            }
            let result = await $api.getAttendanceReport({ ...obj })
            tableData.value = result.data.resource
            pagination.total = result.data.total
        }

        const handleTableChange = (page, filters, order) => {
            if (Object.keys(order).length) {
                sortData.order = order.order
                sortData.field = order.field
                let obj = filterObj(cloneDeep(formData))
                if (order.order == 'descend') {
                    obj.sort = 1
                } else {
                    obj.sort = 0
                }
                getTableData(obj)
            }
        };
        const tableData = ref([])
        const columns = ref([
            {
                title: "Group Lead",
                dataIndex: "fullName",
                key: "fullName",
                ellipsis: true,
            },
            {
                title: "ID Number",
                dataIndex: "idNumber",
                key: "idNumber",
                ellipsis: true,
            },
            {
                title: "Email",
                dataIndex: "email",
                key: "email",
                width: 210,
                ellipsis: true,
            },
            {
                title: "Work Location",
                dataIndex: "workLocation",
                key: "workLocation",
                ellipsis: true,
            },
            {
                title: "Org5.1",
                dataIndex: "orgFiveOne",
                key: "orgFiveOne",
                ellipsis: true,
            },
            {
                title: "实际出勤率",
                dataIndex: "realityAttendance",
                key: "realityAttendance",
                ellipsis: true,
                sorter: true,
                customRender: ({ record }) => {
                    return `${record.realityAttendance + '%'}`
                },
            },
            {
                title: "座位使用数",
                dataIndex: "approvalQuantity",
                key: "approvalQuantity",
                ellipsis: true,
            },
            {
                title: "座位预定数",
                dataIndex: "preApprovalQuantity",
                key: "preApprovalQuantity",
                ellipsis: true,
            },
            {
                title: "座位利用率",
                dataIndex: "utilizationRate",
                key: "utilizationRate",
                ellipsis: true,
                customRender: ({ record }) => {
                    return `${record.utilizationRate + '%'}`
                },
            },
        ])

        return {
            orgListOption,
            workListOption,
            tableHeight,
            value,
            dates,
            disabledDate,
            onOpenChange,
            onChange,
            onCalendarChange,
            formData,
            doSearch,
            resetForm,
            pagination,
            tableData,
            columns,
            handleTableChange,
            sendEmailHandler,
            sendVisible,
            emailData,
            emailRules,
            emailFormRef,
            confirmSend,
            exportClick,
            tableRef
        }
    },
});
</script>
<style scoped lang="less">
.timeRequired {
    display: block !important;
}

.textRequired {
    position: relative;
    bottom: 10px;
}

:deep(.ant-form-item-required) {
    font-weight: 600 !important;
}

:deep(.aModalForm) {
    flex-direction: column !important;

    .ant-form-item {
        margin-bottom: 0px !important;
    }
}
.buttons-wrap {
    :deep(.anticon) {
        font-size: 20px;
    }
}
</style>
