<template>
    <div class="Report">
        <FilterLayout @doReset="resetForm" @doSearch="doSearch" ref="filterLayoutRef" @showMoreForm="showMoreForm"
            :moreBtnVisible="moreBtnVisible" :isShowMoreFlag="isShowMoreList">
            <template #customform>
                <a-form ref="formRef" class="form rms-form" layout="vertical" :model="formData">
                    <template v-for="(item, index) in queryList" :key="index">
                        <template v-if="index < 4">
                            <a-form-item :label="item.code" class="rms-form-item">
                                <template v-if="item.type === '3'">
                                    <a-input allowClear v-model:value="formData[item.inputDataType]"
                                        placeholder='Name/ID Number' />
                                </template>
                                <template v-if="item.type === '6'">
                                    <a-range-picker v-model:value="formData[item.inputDataType]" format="YYYY-MM-DD"
                                        :disabledDate="disabledDate" @change="onChange" @openChange="onOpenChange"
                                        @calendarChange="onCalendarChange" valueFormat="YYYY-MM-DD"
                                        :placeholder="['startDate', 'endDate']" />
                                </template>
                                <template v-if="item.type === '5'">
                                    <SelectWithAll v-model:value="formData[item.inputDataType]" placeholder="请搜索"
                                        mode="multiple" showArrow allowClear :maxTagCount="1">
                                        <a-select-option v-for="jtem in optionsList.offering" :key="jtem"
                                            :value="jtem"><span :title="jtem">{{ jtem }}</span>
                                        </a-select-option>
                                    </SelectWithAll>
                                </template>
                            </a-form-item>
                        </template>
                    </template>
                    <MoreFormList :visible="isShowMoreList" @open="openFormSortBox" @close="closeMoreForm" @moreFormSearch="moreSeachHanlder">
                        <template v-for="(item, index) in queryList" :key="index">
                            <template v-if="index >= 4">
                                <a-form-item :label="item.code" class="rms-form-item">
                                    <template v-if="item.type === '3'">
                                        <a-input allowClear v-model:value="formData[item.inputDataType]"
                                            placeholder="Name/ID Number" />
                                    </template>
                                    <template v-if="item.type === '6'">
                                        <a-range-picker v-model:value="formData[item.inputDataType]" format="YYYY-MM-DD"
                                            :disabledDate="disabledDate" @change="onChange" @openChange="onOpenChange"
                                            @calendarChange="onCalendarChange" valueFormat="YYYY-MM-DD"
                                            :placeholder="['startDate', 'endDate']" />
                                    </template>
                                    <template v-if="item.type === '5'">
                                        <SelectWithAll v-model:value="formData[item.inputDataType]" placeholder="请搜索"
                                            mode="multiple" showArrow allowClear :maxTagCount="1">
                                            <a-select-option v-for="jtem in optionsList.offering" :key="jtem"
                                                :value="jtem"><span :title="jtem">{{ jtem }}</span>
                                            </a-select-option>
                                        </SelectWithAll>
                                    </template>
                                </a-form-item>
                            </template>
                        </template>
                    </MoreFormList>
                </a-form>
            </template>
            <template #operationBtns>
                <div class="buttons-wrap right">
                    <Tooltip title="导出">
                        <a-button type="primary" ghost @click="exportClick">
                            <ExportOutlined />
                        </a-button>
                    </Tooltip>
                    <a-button class="ml12" type="primary" ghost @click="updateHandler">
                        上传数据
                    </a-button>
                    <div class="ml12 specialText">
                        <div>上传日期：{{ importDate }}</div>
                        <div>截止日期：{{ endDate }}</div>
                    </div>
                </div>
            </template>
        </FilterLayout>
        <a-table ref="tableRef" :columns="columns" :data-source="tableData"
            :scroll="{ y: tableHeight, x: 1000, scrollToFirstRowOnChange: true, }" :pagination="pagination">
            <template #userName="{ record }">
                <div style="{'line-height':1.2}">{{ record.userName }}</div>
                <Tooltip :title="record.userEmail">
                    <div style="{'line-height':1.2}">{{ record.userEmail }}</div>
                </Tooltip>
            </template>
            <template #lmName="{ record }">
                <template v-if="(record.lmName == null || record.lmName == '')">
                    <div>--</div>
                </template>
                <template v-else>
                    <div>{{ record.lmName }}</div>
                    <Tooltip :title="record.lmEmail">
                        <div style="{'line-height':1.2}">{{ record.lmEmail }}</div>
                    </Tooltip>
                </template>
            </template>
            <template #groupName="{ record }">
                <template v-if="(record.groupLeadName == null || record.groupLeadName == '')">
                    <div>--</div>
                </template>
                <template v-else>
                    <div>{{ record.groupLeadName }}</div>
                    <Tooltip :title="record.groupLeadEmail">
                        <div>{{ record.groupLeadEmail }}</div>
                    </Tooltip>
                </template>
            </template>
            <template #Offering="{ record }">
                <template v-if="(record.offering == null || record.offering == '')">
                    <div>--</div>
                </template>
                <template v-else>
                    <Tooltip :title="record.offering">
                        <div>{{ record.offering }}</div>
                    </Tooltip>
                </template>
            </template>
        </a-table>
        <!-- apiFuncNames 是导入接口的函数名 -->
        <import-modal v-model:visible="importModalVisible" apiFuncName="importSearReport" @closeModal="handleCloseModal"
            @resetTable="doUpdateImport" @insertModal="doInsertImport" />
        <FormModalBox v-model:visible="isShowFormSortBox" :sortFlag="false" :checkList="checkList" @closeModal="closeModal"
            @reset="queryFormListHandler"></FormModalBox>
    </div>
</template>
<script>
import { defineComponent, reactive, onMounted, ref, toRefs } from "vue";
import { extraListHandler } from '../hooks/handler'
import MoreFormList from '@/components/MoreFormList'
import FormModalBox from '@/components/FormModalBox'
import { ExportOutlined } from "@ant-design/icons-vue";
import { cloneDeep } from "lodash";
import Tooltip from '@/components/Tooltip'
import { useHandler, extraImportHandler } from "../hooks/handler"
import { useGlobalPropertyHook } from '@/hooks/common'
import moment from "moment";
import ImportModal from "@/components/ImportModal";
import { downloadFile } from "@/utils/common";
import { useLifeCycle } from "../hooks/handler"
export default defineComponent({
    name: "Report",
    components: { MoreFormList, FormModalBox, Tooltip, ExportOutlined, ImportModal },
    setup() {
        const { $api } = useGlobalPropertyHook()

        const {
            moreBtnVisible, isShowFormSortBox, isShowMoreList, checkList,
            showMoreForm, closeMoreForm, openFormSortBox, closeModal, queryList, queryFormList
        } = extraListHandler()

        const { importModalVisible, handleImport, handleCloseModal } = extraImportHandler()

        const { filterObj } = useHandler()
        const tableHeight = useLifeCycle()

        const importDate = ref('')
        const endDate = ref('')

        onMounted(() => {
            getOrgList()
            doSearch()
            setTimeout(queryFormListHandler({ isQueryDefault: 0, isQuery: true }), 500)
        })

        let optionsList = reactive({
            offering: []
        })

        const getOrgList = async () => {
            let result = await $api.getOrgTwo_Three_Four_FiveList()
            // orgFourOneOneList 是非联动的取值?
            optionsList.offering = result.data.orgFourOneOneList
        };

        const pagination = reactive({
            current: 1,
            pageSize: 10,
            total: 0,
            showSizeChanger: true,
            showTotal: (total) => {
                return `共 ${total} 条`;
            },
            onChange: (page, pageSize) => {
                pagination.current = page;
                pagination.pageSize = pageSize;
                let obj = filterObj(cloneDeep(formData))
                getTableData(obj);
            },
            onShowSizeChange: (_, size) => {
                pagination.pageSize = size;
                pagination.current = 1;
                let obj = filterObj(cloneDeep(formData))
                getTableData(obj);
            }
        });

        const updateHandler = () => {
            handleImport()
        }

        const disabledDate = (current) => {
            let flag = current && current > moment().endOf("day")
            if (!dates.value || dates.value.length === 0) {
                return flag;
            }
            const diffDate = current.diff(dates.value[0], 'days');
            flag = current && current > moment().endOf("day") || Math.abs(diffDate) > 31
            return flag
        };

        const onOpenChange = (open) => {
            if (open) {
                dates.value = [];
            }
        };

        const value = ref([])
        const dates = ref([])

        const onChange = (val) => {
            value.value = val;
        };

        const onCalendarChange = (val) => {
            dates.value = val;
        };

        let initData = {
            condition: '',
            lmCondition: '',
            groupCondition: '',
            offering: [],
            seatApplicationBeginTime: [
                moment().startOf('isoWeek').subtract(2, 'weeks').format('YYYY-MM-DD'),
                moment().format('YYYY-MM-DD')
            ],
        }

        let formData = reactive({ ...initData });

        const doSearch = () => {
            let obj = filterObj(cloneDeep(formData))
            pagination.current = 1
            getTableData(obj)
            currentState = cloneDeep(formData);
        }
        const resetForm = () => {
            formData = Object.assign(formData, initData)
            let obj = filterObj(cloneDeep(formData))
            pagination.current = 1
            getTableData(obj)
            currentState = cloneDeep(formData);
        }
        const tableData = ref([])
        const getTableData = async (obj) => {
            let { current, pageSize } = toRefs(pagination)
            obj.pageIndex = current.value
            obj.pageSize = pageSize.value
            if (obj.seatApplicationBeginTime && obj.seatApplicationBeginTime.length) {
                let time = obj.seatApplicationBeginTime
                obj.seatApplicationBeginTime = time[0]
                obj.seatApplicationEndTime = time[1]
            }
            let [result, time] = await Promise.all([$api.seatGetAttendTable({ ...obj }), $api.getAttendanceReportTime()])
            pagination.total = result.data.total
            tableData.value = result.data.resource
            importDate.value = time.data.importTime
            endDate.value = `${time.data.maxDate} ${time.data.latestTime}`
        }

        const doUpdateImport = async () => {
            doSearch()
        };

        const doInsertImport = () => {
            doSearch()
        };

        const queryFormListHandler = (data) => {
            queryFormList(data, 'seatManagement', 'seatUsage', 4)
        }

        let currentState = {}
        const exportClick = () => {
            const params = {}
            params.condition = currentState.condition ? currentState.condition : undefined
            params.lmCondition = currentState.lmCondition ? currentState.lmCondition : undefined
            params.groupCondition = currentState.groupCondition ? currentState.groupCondition : undefined
            params.offering = currentState.offering.length > 0 ? currentState.condition : undefined
            params.seatApplicationBeginTime = currentState.seatApplicationBeginTime.length > 0 ? currentState.seatApplicationBeginTime[0] : undefined
            params.seatApplicationEndTime = currentState.seatApplicationBeginTime.length > 0 ? currentState.seatApplicationBeginTime[1] : undefined
            $api.exportStaffReport(params).then((res) => {
                downloadFile(res.file, res.filename);
            })
        }


        const columns = [
            {
                title: "ID Number",
                dataIndex: "idNumber",
                key: "idNumber",
                width: 120,
                ellipsis: true,
            },
            {
                title: "员工",
                dataIndex: "userName",
                key: "userName",
                ellipsis: true,
                width: 210,
                slots: { customRender: "userName" },
                customCell: () => {
                    return {
                        style: {
                            lineHeight: 1.2,
                            padding: '6px 10px',
                        }
                    }
                }
            },
            {
                title: "LM",
                dataIndex: "lmName",
                key: "lmName",
                ellipsis: true,
                width: 210,
                slots: { customRender: "lmName" },
                customCell: () => {
                    return {
                        style: {
                            lineHeight: 1.2,
                            padding: '6px 10px',
                        }
                    }
                }
            },
            {
                title: "Group Lead",
                dataIndex: "groupLeadName",
                key: "groupLeadName",
                ellipsis: true,
                width: 170,
                slots: { customRender: "groupName" },
                customCell: () => {
                    return {
                        style: {
                            lineHeight: 1.2,
                            padding: '6px 10px',
                        }
                    }
                }
            },
            {
                title: "Offering",
                dataIndex: "offering",
                key: "offering",
                width: 170,
                ellipsis: true,
                slots: { customRender: "Offering" },
                customCell: () => {
                    return {
                        style: {
                            lineHeight: 1.2,
                            padding: '6px 10px',
                        }
                    }
                }
            },
            {
                title: "打卡日期",
                dataIndex: "date",
                key: "date",
                width: 110,
                ellipsis: true,
            },
            {
                title: "首次打卡",
                dataIndex: "firstRead",
                key: "firstRead",
                width: 90,
                ellipsis: true,
            },
            {
                title: "末次打卡",
                dataIndex: "lastRead",
                key: "lastRead",
                width: 90,
                ellipsis: true,
            },
            {
                title: "打卡次数",
                dataIndex: "readCount",
                key: "readCount",
                width: 100,
                ellipsis: true,
            },
            {
                title: "时长",
                dataIndex: "duration",
                key: "duration",
                width: 90,
                ellipsis: true,
            },
        ]

        const moreSeachHanlder = () => {
            doSearch()
            closeMoreForm()
        }

        return {
            moreBtnVisible,
            isShowFormSortBox,
            isShowMoreList,
            showMoreForm,
            closeMoreForm,
            formData,
            queryList,
            disabledDate,
            doSearch,
            resetForm,
            openFormSortBox,
            closeModal,
            checkList,
            queryFormList,
            exportClick,
            importModalVisible,
            handleImport,
            handleCloseModal,
            doInsertImport,
            doUpdateImport,
            optionsList,
            queryFormListHandler,
            pagination,
            tableData,
            columns,
            updateHandler,
            importDate,
            endDate,
            onOpenChange,
            onChange,
            onCalendarChange,
            tableHeight,
            moreSeachHanlder
        }
    },
});
</script>
<style scoped lang="less">
.Report {
    .specialText {
        font-size: 12px;
        color: rgb(179, 179, 179)
    }
}

.buttons-wrap {
    :deep(.anticon) {
        font-size: 20px;
    }
}
</style>
