<template>
  <div ref="monthLineChartModalRef">
    <a-modal
        :getContainer="() => $refs.monthLineChartModalRef"
        class="monthLineChartModal"
        :visible="visible"
        @cancel="closeModal"
        width="90vw"
        height="80vh"
        :zIndex="1000"
        centered
        :footer="null"
    >
      <template #title>
        <CardTitle title="座位使用率"/>
      </template>
      <div ref="chartRef" id="chartRef" style="width: 100%; height: 70vh"></div>
    </a-modal>
  </div>
</template>

<script>
import * as echarts from "echarts";
import {defineComponent, nextTick, reactive, ref, watch} from "vue";
import CardTitle from "@/components/CardTitle.vue";
import moment from "moment";

export default defineComponent({
  name: "MonthLineChartModal",
  emits: ["close-modal"],
  components: {CardTitle},
  props: {
    visible: Boolean,
    chartData: {
      type: Array,
      default: () => {
        return []
      }
    },
    currentYear: {
      type: String,
      default: "",
    },
  },
  setup(props,{ emit }) {
    const chartRef = ref(null)
    const states = reactive({
      option :  {
        tooltip: {},
        legend: {
          show: true,
          right: 0,
          top: 0,
          icon: "circle",
          itemGap: 20,
          selectedMode: true,
          itemWidth: 10,
          itemHeight: 10,
          textStyle: { fontSize: '14px' },
        },
        grid: {
          left: '4%',
          right: '4%',
          bottom: '6%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ["一月", "二月", "三月","四月","五月","六月","七月","八月","九月","十月","十一月","十二月"],
          axisLabel: { color: "#333333" },
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            show:true,
            formatter: '{value}%',
            color: "#999999"
          },
          max: 100, // 设置最大值
          min: 0, // 设置最小值
          interval: 20 // 设置刻度间距
        },
        color: ['#DC5E4F','#58B99E','#1890FF','#FDCD46'],
        series:[]
      }
    })
    watch(
        () => [props.chartData,props.visible],
        (val) => {
          if(val[0]&&val[1]){
            states.option.tooltip = {
              trigger: "axis",
              axisPointer: {
                type: "line",
              },
              formatter: (params) => {
                let result = ""
                result += params[0].name +"<br>"
                params.forEach(item => {
                  result += `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px; color: ${item.color}">●</span>`
                  if(moment().isAfter(props.currentYear, 'year')) {
                    result += item.seriesName + " : " +item.data+ "%" +"<br>"
                  } else if(moment().isSame(props.currentYear, 'year')) {
                    let arr =[
                      {value:'一月',label:moment().format("YYYY")+'-01'},
                      {value:'二月',label:moment().format("YYYY")+'-02'},
                      {value:'三月',label:moment().format("YYYY")+'-03'},
                      {value:'四月',label:moment().format("YYYY")+'-04'},
                      {value:'五月',label:moment().format("YYYY")+'-05'},
                      {value:'六月',label:moment().format("YYYY")+'-06'},
                      {value:'七月',label:moment().format("YYYY")+'-07'},
                      {value:'八月',label:moment().format("YYYY")+'-08'},
                      {value:'九月',label:moment().format("YYYY")+'-09'},
                      {value:'十月',label:moment().format("YYYY")+'-10'},
                      {value:'十一月',label:moment().format("YYYY")+'-11'},
                      {value:'十二月',label:moment().format("YYYY")+'-12'},
                    ]
                    let currentMonth = arr.filter(i => i.value === item.axisValue)[0].label
                    console.log(currentMonth)
                    if(moment().isBefore(currentMonth, 'month')) {
                      result += item.seriesName + " : " +"--" +"<br>"
                    } else {
                      result += item.seriesName + " : " +item.data+ "%" +"<br>"
                    }
                  } else {
                    if(item.data === 0) {
                      result += item.seriesName + " : " +"--" +"<br>"
                    } else {
                      result += item.seriesName + " : " +item.data+ "%" +"<br>"
                    }
                  }
                })
                return result
              }
            }
            states.option.series = [
              ...val[0].map(item => {
                let arr = [item.janBookingRate,item.febBookingRate,item.marBookingRate,item.aprBookingRate,item.mayBookingRate,item.junBookingRate,item.julBookingRate,item.augBookingRate,item.sepBookingRate,item.octBookingRate,item.novBookingRate,item.decBookingRate]
                return {
                  type: 'line',
                  name: item.building+'/'+item.floor,
                  data: arr,
                }
              })
            ]
            nextTick(() => {
              let myChart = echarts.getInstanceByDom(document.getElementById("chartRef"))
              if(!myChart){
                myChart = echarts.init(chartRef.value)
              }
              myChart.setOption(states.option,true)
            });
          }
        }
    )

    const closeModal = () => {
      emit("close-modal");
    };
    return {
      chartRef,
      closeModal,
      states,
    }
  }
})
</script>

<style scoped lang="less">
.monthLineChartModal {

}
:deep(.card-title) {
  font-weight: 400;
  font-size: 24px;
  &::before {
    top: 0.2vw;
    height: 24px;
  }
}
:deep(.ant-modal-header){
  background-color: #FFFFFF;
}
</style>
