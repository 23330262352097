import { ref, onMounted, onUnmounted, nextTick } from "vue";
import { useTableHeight } from "@/utils/common";
import { useGlobalPropertyHook } from '@/hooks/common'

const useHandler = () => {
    // 过滤null,undefined,空数组[],空字符串'',方便传值给后端
    const filterObj = (obj) => {
        if (!obj) return obj; // 如果传入的值为null或undefined，直接返回该值
        Object.keys(obj).forEach(function (key) {
            if (obj[key] === '' || obj[key] === null || (Array.isArray(obj[key]) && obj[key].length === 0)) {
                delete obj[key];
            } else if (typeof obj[key] === 'object' && obj[key] !== null) {
                filterObj(obj[key]);
            }
        });
        return obj;
    }

    return {
        filterObj
    }
}

const useLifeCycle = () => {
    const tableHeight = ref(0);
    onMounted(() => {
        window.addEventListener("resize", getSize)
        getSize()
    })
    onUnmounted(() => {

        window.removeEventListener("resize", getSize);
    })
    const getSize = () => {
        nextTick(() => {
            tableHeight.value = useTableHeight();
        });
    };
    return tableHeight
}

// 搜索条件时候存在更多
const extraListHandler = () => {
    const { $api } = useGlobalPropertyHook()

    const moreBtnVisible = ref(false)

    const isShowFormSortBox = ref(false)

    let isShowMoreList = ref(false)

    const checkList = ref([])

    const queryList = ref([])

    const showMoreForm = (data) => {
        isShowMoreList.value = data
    }
    const closeMoreForm = () => {
        isShowMoreList.value = false
    }

    const openFormSortBox = () => {
        isShowFormSortBox.value = true
    }

    const closeModal = () => {
        isShowFormSortBox.value = false
    }

    const queryFormList = async (data, firstMenu, secondMenu, index) => {
        let res = await $api.queryFilterSort({
            firstMenu: firstMenu,
            secondMenu: secondMenu,
            isQueryDefault: data.isQueryDefault
        })
        if (data.isQuery) {
            queryList.value = res.data.filter((item) => {
                return item.isDeleted === '0'
            })
            queryList.value.length > index ? moreBtnVisible.value = true : moreBtnVisible.value = false
            checkList.value = [...queryList.value]
        } else {
            checkList.value = res.data.filter((item) => {
                return item.isDeleted === '0'
            })
        }
    }
    return {
        moreBtnVisible,
        isShowFormSortBox,
        isShowMoreList,
        showMoreForm,
        closeMoreForm,
        openFormSortBox,
        closeModal,
        checkList,
        queryFormList,
        queryList
    }
}

// 导入
const extraImportHandler = () => {
    const importModalVisible = ref(false)
    const handleImport = () => {
        importModalVisible.value = true;
    }
    const handleCloseModal = () => {
        importModalVisible.value = false;
    };
    return {
        importModalVisible,
        handleImport,
        handleCloseModal
    }
}


export { useHandler, useLifeCycle, extraListHandler, extraImportHandler }