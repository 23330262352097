<template>
    <div class="Seat">
        <FilterLayout @doReset="resetForm" @doSearch="doSearch" ref="filterLayoutRef" >
            <template #customform>
                <a-form ref="formRef" class="form rms-form box" layout="vertical" :model="formData">
                    <a-form-item v-for="item in selectList" :key="item" :label="item" :name="item" class="rms-form-item">
                        <template v-if="item == 'Month'">
                            <a-month-picker :allowClear="false" format="YYYY-MM" class="item" :disabled-date="disabledDate"
                                v-model:value="formData['reportTime']" placeholder="Select month" />
                        </template>
                        <template v-else-if="item == 'Year'">
                            <a-date-picker :allowClear="false" format="YYYY" mode="year" class="item" :open="isYearOpen"
                                :disabled-date="disabledDate" v-model:value="formData['reportTime']"
                                placeholder="Select Year" @openChange="openChange" @panelChange="panelChange" />
                        </template>
                        <template v-else>
                            <SelectWithAll v-model:value="formData[item]" placeholder="请选择" mode="multiple" showArrow
                                allowClear :maxTagCount="1" @change="selectChangeHandler(item)"
                                :disabled="!optionList[item].length">
                                <a-select-option v-for="jtem in optionList[item]" :key="jtem.name" :value="jtem.code"><span
                                        :title="jtem.name">{{ jtem.name }}</span>
                                </a-select-option>
                            </SelectWithAll>
                        </template>
                    </a-form-item>
                </a-form>
            </template>
            <template #operationBtns>
                <div class="buttons-wrap right">
                    <template v-if="typeValue == 'report'">
                        <Tooltip title="导出">
                            <a-button class="" type="primary" ghost @click="exportClick">
                                <ExportOutlined />
                            </a-button>
                        </Tooltip>
                    </template>
                    <template v-else>
                        <a-button class="ml12" type="primary" ghost @click="checkMonthlChart">
                            <BarChartOutlined /> 查看图示
                        </a-button>
                    </template>
                    <a-select class="ml12" v-model:value="typeValue" :options="typeList"
                        @change="typeChangeHandler"></a-select>
                </div>
            </template>
        </FilterLayout>
        <a-table ref="tableRef" :columns="columns" :data-source="tableData" rowKey="floor"
            :scroll="{ y: tableHeight, x: 1000 }" :pagination="pagination">
            <template #action="{ record }">
                <ActionBtn v-bind="record" :btnList="btnList" @EyeOutlined="checkSeatDetails(record)" />
            </template>

        </a-table>
        <div ref="emailContainerRef">
            <a-modal v-model:visible="sendVisible" title="发送现场管理周报" width="50vw"
                :getContainer="() => $refs.emailContainerRef" :maskClosable="false">
                <a-form ref="emailFormRef" class="form rms-form box aModalForm" :model="emailData" :rules="emailRules">
                    <a-form-item class="timeRequired" required label="请选择统计日期" name="emailTime">
                        <a-range-picker v-model:value="emailData.emailTime" :placeholder="['起始日期', '终止日期']"
                            format="YYYY-MM-DD" valueFormat="YYYY-MM-DD">
                            <template #suffixIcon>
                                <CalendarOutlined /> 
                            </template>
                        </a-range-picker>
                    </a-form-item>
                    <div>
                        <a-form-item class="timeRequired textRequired" required label="请选择收件人" name="emailText">
                            <a-textarea :auto-size="{ minRows: 12 }" v-model:value="emailData.emailText"
                                placeholder='请输入收件人邮箱，如有多个请使用"；"隔开' />
                        </a-form-item>
                    </div>

                </a-form>
                <template #footer>
                    <a-button @click="sendVisible = !sendVisible">取消</a-button>
                    <a-button type="primary" @click="confirmSend">确定</a-button>
                </template>
            </a-modal>
        </div>

        <MonthLineChartModal :visible="chartVisible" :chart-data="lineChartData" @close-modal="chartModalClose"
            :currentYear="currentYear" />
    </div>
</template>
<script>
import moment from "moment";
import { defineComponent, onMounted, reactive, ref, computed } from "vue";
import { useGlobalPropertyHook } from '@/hooks/common'
import {
    BarChartOutlined,
    ExportOutlined,
    CalendarOutlined,
} from "@ant-design/icons-vue";
import Tooltip from '@/components/Tooltip'
import { useHandler, useLifeCycle } from "../hooks/handler"
import { cloneDeep } from "lodash";
import { useRouter } from "vue-router";
import MonthLineChartModal from "@/views/home/Seat/SeatUsage/components/MonthLineChartModal.vue";
import { downloadFile } from "@/utils/common";
import ActionBtn from "@/components/ActionBtn.vue";
export default defineComponent({
    name: "Seat",
    components: {
        ExportOutlined,
        MonthLineChartModal,
        BarChartOutlined,
        Tooltip,
        ActionBtn,
        CalendarOutlined
    },
    setup() {
        const { $api } = useGlobalPropertyHook()
        const { filterObj } = useHandler()
        const tableHeight = useLifeCycle()

        onMounted(() => {
            getOptionHandler()
            doSearch()
        })

        // 下拉筛选全部List
        const originList = {}
        // 下拉筛选动态下拉框
        const optionList = reactive({
            City: [],
            Building: [],
            Floor: []
        })

        const btnList = [
            {
                name: '详情',
                icon: 'EyeOutlined',
                auth: []
                // auth: ['system:my:seatApproval:details']
            }
        ]

        const getOptionHandler = async () => {
            let { data } = await $api.queryBaseData({ "types": ["city", "work_location", "floor"] })
            originList.City = data.filter((item) => { return item.type == "city" })
            optionList.City = data.filter((item) => { return item.type == "city" })
            originList.Building = data.filter((item) => { return item.type == 'work_location' })
            originList.Floor = data.filter((item) => { return item.type == 'floor' })
        }

        const tableData = ref([])
        const columns = computed(() => {
            if (typeValue.value == 'report') {
                return [
                    {
                        title: "City",
                        dataIndex: "city",
                        key: "city",
                        ellipsis: true,
                    },
                    {
                        title: "Building",
                        dataIndex: "building",
                        key: "building",
                        ellipsis: true,
                    },
                    {
                        title: "Floor",
                        dataIndex: "floor",
                        key: "floor",
                        ellipsis: true,
                    },
                    {
                        title: "座位数",
                        dataIndex: "seatSumCount",
                        key: "seatSumCount",
                        ellipsis: true,
                    },
                    {
                        title: "可用座位数",
                        dataIndex: "canUseSeatCount",
                        key: "canUseSeatCount",
                        ellipsis: true,

                    },
                    {
                        title: "预定数",
                        dataIndex: "bookingCount",
                        key: "bookingCount",
                        ellipsis: true,
                    },
                    {
                        title: "人员预订数",
                        dataIndex: "personBookingCount",
                        key: "personBookingCount",
                        ellipsis: true,
                    },
                    {
                        title: "人员待定数",
                        dataIndex: "pendingCount",
                        key: "pendingCount",
                        ellipsis: true,
                    },
                    {
                        title: "预订率",
                        dataIndex: "bookingRate",
                        key: "bookingRate",
                        ellipsis: true,
                        customRender: ({ record }) => {
                            return `${record.bookingRate == 0 ? '--' : record.bookingRate + '%'}`
                        },
                    },
                    {
                        title: "操作",
                        dataIndex: "action",
                        key: "action",
                        slots: { customRender: "action" },
                        width: 80,
                        fixed: "right",
                    },
                ]
            } else {
                return [
                    {
                        title: "City",
                        dataIndex: "city",
                        key: "city",
                        ellipsis: true,
                    },
                    {
                        title: "Building",
                        dataIndex: "building",
                        key: "building",
                        ellipsis: true,
                    },
                    {
                        title: "Floor",
                        dataIndex: "floor",
                        key: "floor",
                        ellipsis: true,
                    },
                    {
                        title: "预订率",
                        dataIndex: "avgBookingRate",
                        key: "avgBookingRate",
                        ellipsis: true,
                        customRender: ({ record }) => {
                            return `${record.avgBookingRate == 0 ? '--' : record.avgBookingRate + '%'}`
                        },
                    },
                    {
                        title: "Jan",
                        dataIndex: "janBookingRate",
                        key: "janBookingRate",
                        ellipsis: true,
                        customRender: ({ record }) => {
                            return `${record.janBookingRate == 0 ? '--' : record.janBookingRate + '%'}`
                        },
                    },
                    {
                        title: "Feb",
                        dataIndex: "febBookingRate",
                        key: "febBookingRate",
                        ellipsis: true,
                        customRender: ({ record }) => {
                            return `${record.febBookingRate == 0 ? '--' : record.febBookingRate + '%'}`
                        },
                    },
                    {
                        title: "Mar",
                        dataIndex: "marBookingRate",
                        key: "marBookingRate",
                        ellipsis: true,
                        customRender: ({ record }) => {
                            return `${record.marBookingRate == 0 ? '--' : record.marBookingRate + '%'}`
                        },
                    },
                    {
                        title: "Apr",
                        dataIndex: "aprBookingRate",
                        key: "aprBookingRate",
                        ellipsis: true,
                        customRender: ({ record }) => {
                            return `${record.aprBookingRate == 0 ? '--' : record.aprBookingRate + '%'}`
                        },
                    },
                    {
                        title: "May",
                        dataIndex: "mayBookingRate",
                        key: "mayBookingRate",
                        ellipsis: true,
                        customRender: ({ record }) => {
                            return `${record.mayBookingRate == 0 ? '--' : record.mayBookingRate + '%'}`
                        },
                    },
                    {
                        title: "Jun",
                        dataIndex: "junBookingRate",
                        key: "junBookingRate",
                        ellipsis: true,
                        customRender: ({ record }) => {
                            return `${record.junBookingRate == 0 ? '--' : record.junBookingRate + '%'}`
                        },
                    },
                    {
                        title: "Jul",
                        dataIndex: "julBookingRate",
                        key: "julBookingRate",
                        ellipsis: true,
                        customRender: ({ record }) => {
                            return `${record.julBookingRate == 0 ? '--' : record.julBookingRate + '%'}`
                        },
                    },
                    {
                        title: "Aug",
                        dataIndex: "augBookingRate",
                        key: "augBookingRate",
                        ellipsis: true,
                        customRender: ({ record }) => {
                            return `${record.augBookingRate == 0 ? '--' : record.augBookingRate + '%'}`
                        },
                    },
                    {
                        title: "Sep",
                        dataIndex: "sepBookingRate",
                        key: "sepBookingRate",
                        ellipsis: true,
                        customRender: ({ record }) => {
                            return `${record.sepBookingRate == 0 ? '--' : record.sepBookingRate + '%'}`
                        },
                    },
                    {
                        title: "Oct",
                        dataIndex: "octBookingRate",
                        key: "octBookingRate",
                        ellipsis: true,
                        customRender: ({ record }) => {
                            return `${record.octBookingRate == 0 ? '--' : record.octBookingRate + '%'}`
                        },
                    },
                    {
                        title: "Nov",
                        dataIndex: "novBookingRate",
                        key: "novBookingRate",
                        ellipsis: true,
                        customRender: ({ record }) => {
                            return `${record.novBookingRate == 0 ? '--' : record.novBookingRate + '%'}`
                        },
                    },
                    {
                        title: "Dec",
                        dataIndex: "decBookingRate",
                        key: "decBookingRate",
                        ellipsis: true,
                        customRender: ({ record }) => {
                            return `${record.decBookingRate == 0 ? '--' : record.decBookingRate + '%'}`
                        },
                    },
                ];
            }
        })

        const disabledDate = (current) => {
            return current && current > moment().endOf("day")
        }

        let initData = {
            year: '',
            month: '',
            reportTime: moment(),
            City: [],
            Building: [],
            Floor: []
        }

        let formData = reactive({ ...initData });

        let selectList = ref(['City', 'Building', 'Floor', 'Month'])

        const doSearch = async () => {
            let obj = filterObj(cloneDeep(formData))
            pagination.current = 1
            getTableData(obj)
            currentState = cloneDeep(formData);
        }

        const resetForm = async () => {
            formData = Object.assign(formData, { ...initData, reportTime: moment() })
            optionList.Building = []
            optionList.Floor = []
            let obj = filterObj(cloneDeep(formData))
            pagination.current = 1
            getTableData(obj)
            currentState = cloneDeep(formData);
        }

        const getTableData = async (obj) => {
            if ('City' in obj) {
                obj.city = obj.City
                delete obj.City
            } else {
                obj.city = []
            }
            if ('Building' in obj) {
                obj.building = obj.Building
                delete obj.Building
            } else {
                obj.building = []
            }
            if ('Floor' in obj) {
                obj.floor = obj.Floor
                delete obj.Floor
            } else {
                obj.floor = []
            }
            obj.pageIndex = pagination.current
            obj.pageSize = pagination.pageSize
            if (typeValue.value == 'report') {
                let time = obj.reportTime.format('YYYY-MM')
                obj.year = time.split('-')[0]
                obj.month = time.split('-')[1]
                delete obj.reportTime
                let result = await $api.seatTableReport({ ...obj })
                tableData.value = result.data.resource
                pagination.total = result.data.total
            } else {
                let time = obj.reportTime.format('YYYY')
                obj.year = time
                delete obj.reportTime
                let result = await $api.seatGetTableRate({ ...obj })
                tableData.value = result.data.resource
                pagination.total = result.data.total
            }
        }

        const pagination = reactive({
            current: 1,
            pageSize: 10,
            total: 0,
            showSizeChanger: true,
            showTotal: (total) => {
                return `共 ${total} 条`;
            },
            onChange: (page, pageSize) => {
                pagination.current = page;
                pagination.pageSize = pageSize;
                let obj = filterObj(cloneDeep(formData))
                getTableData(obj);
            },
            onShowSizeChange: (_, size) => {
                pagination.pageSize = size;
                pagination.current = 1;
                let obj = filterObj(cloneDeep(formData))
                getTableData(obj);
            }
        });


        const typeValue = ref('report')

        const typeList = reactive([
            { label: '座位报表', value: 'report' }, { label: '座位使用率', value: 'rate' }
        ])

        const typeChangeHandler = () => {
            tableData.value = []
            formData.reportTime = moment()
            if (typeValue.value == 'report') {
                selectList.value = ['City', 'Building', 'Floor', 'Month']
            } else {
                selectList.value = ['City', 'Building', 'Floor', 'Year']
            }
            doSearch()
        }

        const isYearOpen = ref(false);

        const selectChangeHandler = (item) => {
            if (item == 'City') {
                if (!Object.values(formData[item]).length) {
                    optionList.Building = []
                    formData.Building = []
                    optionList.Floor = []
                    formData.Floor = []
                } else {
                    optionList.Building = originList.Building.filter((jtem) => { return Object.values(formData[item]).includes(jtem.uplevel) })
                    let allId = optionList.Building.map((jtem) => { return jtem.code })
                    formData.Building = formData.Building.filter((jtem) => { return allId.includes(jtem) })
                }
            }
            if (item == 'Building') {
                if (!Object.values(formData[item]).length) {
                    optionList.Floor = []
                    formData.Floor = []
                } else {
                    optionList.Floor = originList.Floor.filter((jtem) => { return Object.values(formData[item]).includes(jtem.uplevel) })
                    let allId = optionList.Floor.map((jtem) => { return jtem.code })
                    formData.Floor = formData.Floor.filter((jtem) => { return allId.includes(jtem) })
                }
            }
        }

        const openChange = (panelStatus) => {
            if (panelStatus) {
                isYearOpen.value = true;
            } else {
                isYearOpen.value = false;
            }
        };

        const panelChange = (val) => {
            formData.reportTime = val
        };

        const router = useRouter();
        const checkSeatDetails = (record) => {
            localStorage.setItem('floor', record.floor)
            router.push("/seat/seatStatisticsDetails");
        }

        const currentYear = ref("")
        const lineChartData = ref([])
        const checkMonthlChart = () => {
            currentYear.value = currentState.reportTime.format('YYYY').split('-')[0]
            lineChartData.value = tableData.value
            chartVisible.value = true
        }
        const chartVisible = ref(false)
        const chartModalClose = () => {
            chartVisible.value = false
        }
        let currentState = {}
        const exportClick = () => {
            const params = {
                city: currentState.City,
                building: currentState.Building,
                floor: currentState.Floor,
                year: currentState.reportTime.format('YYYY-MM').split('-')[0],
                month: currentState.reportTime.format('YYYY-MM').split('-')[1],
            }
            $api.exportSeatUsageReport(params).then((res) => {
                downloadFile(res.file, res.filename);
            })
        }

        return {
            tableData,
            columns,
            tableHeight,
            optionList,
            formData,
            doSearch,
            resetForm,
            disabledDate,
            typeValue,
            typeList,
            selectList,
            selectChangeHandler,
            typeChangeHandler,
            isYearOpen,
            openChange,
            panelChange,
            checkSeatDetails,
            checkMonthlChart,
            chartVisible,
            chartModalClose,
            pagination,
            exportClick,
            lineChartData,
            btnList,
            currentYear,
        }
    },
});
</script>
<style scoped lang="less">
.Seat {
    .item {
        width: 100%;
    }
}


.buttons-wrap {
    .ant-btn {
        :deep(.anticon) {
            font-size: 20px;
        }
    }
}
</style>
