<template>
  <div class="SeatUsage">
    <div class="tab">
      <a-tabs v-model:activeKey="activeKey" @change="tabClcikHandler">
        <a-tab-pane v-for="item in tabList" :key="item.value" :tab="item.name"></a-tab-pane>
      </a-tabs>
    </div>
    <div class="tab-container">
      <component :is="activeKey" />
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from "vue";
import Report from "./components/Report.vue"
import Analysis from "./components/Analysis.vue"
import Seat from "./components/Seat.vue"
export default defineComponent({
  components: {
    Report,
    Analysis,
    Seat
  },
  name: "seatUsage",
  setup() {
    // tab 栏目部分
    const activeKey = ref('Report')
    const tabList = ref([{ value: 'Report', name: '员工出勤报告' }, { value: 'Analysis', name: '出勤统计' }, { value: 'Seat', name: '座位统计' }])
    const tabClcikHandler = (value) => {
      activeKey.value = value
    }
    return {
      activeKey,
      tabList,
      tabClcikHandler,
    };
  },
});
</script>
<style scoped lang="less">
.SeatUsage {
  padding: 0 20px;
}

.tab {
  :deep(.ant-tabs-tab) {
    color: rgb(153, 153, 153) !important;
    font-weight: 700;
  }

  :deep(.ant-tabs-top-bar) {
    margin-bottom: 0px !important;
  }

  :deep(.ant-tabs-tab-active) {
    color: rgb(49, 130, 206) !important;
    font-weight: 700;
  }

  :deep(.ant-tabs-content) {
    display: none !important;
  }
}

.tab-container {
  width: 100%;
  height: 82vh;
  overflow: hidden;
  background-color: white;
  border-radius: 6px;
  padding: 0px 20px;
}
</style>
